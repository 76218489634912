import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PROJECT_TYPES } from '../../constants/projectTypes';

import styles from './projectSubfilter.module.css';

function SubFilter({ currentType, setType, locale }) {
  const typesNumber = Object.keys(PROJECT_TYPES).length;
  function onTypeClick(type) {
    setType(type);
  }
  return (
    <ul className={styles.subFilter}>
      {[{ value: 'all', fr: 'Tous', 'en-US': 'All' }, ...Object.values(PROJECT_TYPES)].map((type, index) => {
        if (index < typesNumber)
          return (
            <Fragment key={type.value}>
              <li
                onClick={() => onTypeClick(type.value)}
                className={currentType.includes(type.value) ? styles.selectedSubFilter : ''}
              >
                {type[locale]}
              </li>
              <li>-</li>
            </Fragment>
          );
        return (
          <li
            key={type.value}
            onClick={() => onTypeClick(type.value)}
            className={currentType.includes(type.value) ? styles.selectedSubFilter : ''}
          >
            {type[locale]}
          </li>
        );
      })}
    </ul>
  );
}
SubFilter.propTypes = {
  currentType: PropTypes.array.isRequired,
  setType: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default SubFilter;
