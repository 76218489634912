import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Layout from '../../components/layout';
import SubFilter from '../../components/projectSubfilter';
import useMediaQuery from '../../hooks/useMediaQuery';

import { LanguageContext, ProjectTypeNavigationContext, ProjectTypeNavigationChangeContext } from '../../context';
import styles from './projectList.module.css';

function ListWithContext(props) {
  const { projects } = props;
  const locale = useContext(LanguageContext);
  const currentProjectType = useContext(ProjectTypeNavigationContext);
  const onChangeProjectType = useContext(ProjectTypeNavigationChangeContext);
  let currentYear = 0;
  const isMobile = useMediaQuery('(max-width:480px)');

  function HandleOnClick(type, event) {
    if (currentProjectType === 'all') return;
    if (!type.includes(currentProjectType)) {
      event.preventDefault();
    }
  }

  function renderSubFilterIfNotMobile() {
    if (isMobile) return false;
    return <SubFilter currentType={[currentProjectType]} setType={onChangeProjectType} locale={locale} />;
  }

  return (
    <>
      <Helmet title="Projets" />
      {renderSubFilterIfNotMobile()}
      <div className={styles.gridWrapper}>
        {projects.edges.map(({ node }) => {
          if (isMobile && !node.type.includes(currentProjectType) && currentProjectType !== 'all') return false;
          const image = get(node, 'imageDeLaListe');
          const boxes = [];

          // for each end date year, we want to display an empty box with the year.
          if (node.dateDeFin && currentYear !== moment(node.dateDeFin).year()) {
            currentYear = moment(node.dateDeFin).year();
            boxes.push(
              <div key={currentYear} className={styles.imageAndTextWrapper}>
                <span className={styles.year}>{currentYear}</span>
              </div>
            );
          }

          const boxClassName =
            node.type.includes(currentProjectType) || currentProjectType === 'all'
              ? styles.imageAndTextWrapper
              : `${styles.imageAndTextWrapper} ${styles.notCurrentTypeSelected}`;
          boxes.push(
            <div key={node.contentful_id} className={boxClassName}>
              <Link
                to={`/${locale === 'fr' ? 'projets' : 'projects'}/${node.slug}`}
                onClick={(event) => HandleOnClick(node.type, event)}
              >
                <Img className={styles.image} alt={image.id} fluid={image.fluid} />
                <div className={styles.text}>{node.titre}</div>
              </Link>
            </div>
          );

          return boxes;
        })}
      </div>
    </>
  );
}
ListWithContext.propTypes = {
  projects: PropTypes.object.isRequired,
};

function ProjectListTemplate(props) {
  const projects = get(props, 'data.allContentfulProject');
  const location = get(props, 'location');

  return (
    <Layout location={location}>
      <ListWithContext projects={projects} />
    </Layout>
  );
}

export default ProjectListTemplate;

export const pageQuery = graphql`
  query AllProject($locale: String!) {
    allContentfulProject(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [dateDeFin, dateDeDebut], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          node_locale
          titre
          type
          dateDeFin
          slug
          imageDeLaListe {
            id
            description
            fluid {
              ...GatsbyContentfulFluid
            }
            file {
              details {
                size
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
